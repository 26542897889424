<template>
  <footer id="Contact" name="Contact" class="view-detect">
    <img class="pattern" src="/assets/pattern.png" />
    <div class="container">
      <h2>Contact us</h2>
      <div class="offices">
        <div v-bind:key="office.title" v-for="office in offices" class="office">
          <h3>{{ office.title }}</h3>
          <p>{{ office.email }}</p>
          <p v-html="office.address"></p>
        </div>
      </div>
    </div>

    <div class="site-end-line">
      <a href="#Home"><img class="logo" src="/assets/logo.svg" alt="QED Software" /></a>
      © 2020 QED Software
    </div>
  </footer>
</template>

<script>
export default {
  data: function () {
    return {
      offices: [
        {
          title: "Auckland",
          email: "hello@mahi.software",
          address: `37 Charlotte Street<br />Eden Terrace, Auckland 1021<br />New Zealand`,
        },
        {
          title: "New York",
          email: "hello@mahi.software",
          address: `175 Pearl St,<br />Brooklyn, NY, 11201<br />USA`,
        },
      ],
    };
  },
  methods: {},
  mounted: function () {},
};
</script>
<template>
  <div class="bits-wrapper">
    <div id="bits-trigger1"></div>
    <section class="bits" v-bind:class="{'ready': ready}">
      <div>
        <img id="bit1" class="bit c-input" src="../assets/components/component-input.png" />

        <img id="bit2" class="bit c-comment" src="../assets/components/component-comment.png" />
        <img id="bit3" class="bit c-wysiwyg" src="../assets/components/component-wyswig.png" />

        <img id="bit4" class="bit c-slider" src="../assets/components/component-60-slider.png" />
      </div>
      <div>
        <div>
          <img id="bit0" class="bit c-time" src="../assets/components/component-time.png" />
          <img
            @load="ready = true"
            id="bit00"
            class="bit c-menu"
            src="../assets/components/component-menu.png"
          />
        </div>

        <img id="bit11" class="bit c-details" src="../assets/components/component-details.png" />
      </div>
      <div>
        <div>
          <img id="bit5" class="bit c-button" src="../assets/components/component-button.png" />
          <img id="bit6" class="bit c-tag" src="../assets/components/component-tag.png" />
        </div>

        <img id="bit7" class="bit c-menubar" src="../assets/components/component-menubar.png" />

        <img id="bit8" class="bit c-upload" src="../assets/components/component-upload.png" />
        <img id="bit9" class="bit c-actions" src="../assets/components/component-actions.png" />
        <img id="bit10" class="bit c-widget" src="../assets/components/component-widget.png" />
      </div>
    </section>
  </div>
</template>
 
<script>
export default {
  data: function () {
    return {
      ready: false,
    };
  },
  methods: {},
  mounted: function () {},
};
</script>
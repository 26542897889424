<template>
  <div v-bind:class="{'loaded': loaded, 'ready': ready}" style="opacity:0" id="Home"  name="Home">
    <section class="home-hero">
      <div class="container">
        <div class="content omega-hero">
          <div id="pin1" class="pin">
            <div class="home-hero-content">
              <div id="qed-logo-trigger"></div>
              <div id="qed">
                <img src="/Q.svg" alt="QED Q Logo" />
                <img src="/ED.svg" alt="QED ED Logo" />
              </div>
              <h1 id="h1">{{header.title}}</h1>
              <p id="home-p">{{header.content}}</p>
            </div>
          </div>
        </div>
      </div>

      <img class="pattern" src="/assets/pattern.png" />
    </section>

    <Bits></Bits>

    <div id="Products" name="Products"></div>
    <template v-for="feature in features">
      <Feature v-bind:key="feature.id" :content="feature" />
    </template>

    <div class="team-wrapper">
      <div class="container">
        <TeamBubbles></TeamBubbles>
      </div>
    </div>
  </div>
</template>

<script>
import inView from "in-view";

import Header from "../components/Header";
import Bits from "../components/Bits";
import SiteFooter from "../components/Footer";
import Feature from "../components/Feature";
import TeamBubbles from "../components/TeamBubbles.vue";

//data
import { Features } from "../data/Features.js";

export default {
  components: { Header, Feature, SiteFooter, TeamBubbles, Bits },
  data: function () {
    return {
      ready: false,
      loaded: false,
      header: {
        title:
          "Build software solutions quickly at scale with less constraints.",
        content: `QED is a suite of interlocking software components, each managing one part of a larger system. This makes QED a uniquely iterative platform, one that can be configured to deliver a range of different business systems.`,
      },
      features: Features,
    };
  },
  methods: {},
  mounted: function () {
    // Initial load

    window.addEventListener("load", (event) => {
      this.loaded = true;
    });

    // InView

    inView.threshold(0.35);

    inView(".view-detect").on("enter", (el) => {
      el.classList.add("in-view");
      el.classList.add("img-in-view");
    });

    inView(".view-detect").on("exit", (el) => {
      if (!el.classList.contains("qed-people")) {
        el.classList.remove("in-view");
      }
    });

    inView(".view-detect2").on("enter", (el) => {
      el.classList.add("in-view");
    });

    // ScrollMagic
    var controller = new ScrollMagic.Controller();

    // QED Logo

    var tl = new TimelineMax();
    var scene = new ScrollMagic.Scene({
      triggerElement: "#qed-logo-trigger",
      duration: "100",
    });

    var tl = new TimelineMax();

    tl.to("#main-logo", 1, {
      y: 100,
      opacity: 1,
    });

    scene.setTween(tl).addTo(controller);

    // Bits

  /*
    var scene = new ScrollMagic.Scene({ duration: 100 })
      .setPin("#pin1")
      .addTo(controller);*/

    var scene = new ScrollMagic.Scene({
      triggerElement: "#title-trigger",
      duration: 300,
    }).addTo(controller);

    var tl = new TimelineMax();

    tl.to("#bit0", 1, {
      y: -140,
      x: 30,
    });

    tl.to(
      "#h1",
      1.5,
      {
        scale: 0.75,
      },
      "-=1"
    );

    tl.to(
      "#qed",
      1.5,
      {
        scale: 0.6,
        y: 50
      },
      "-=1"
    );
    

    tl.to(
      "#bit00",
      1,
      {
        x: -30,
      },
      "-=2"
    );

    tl.to(
      "#bit2",
      1,
      {
        x: 870,
      },
      "-=0.86"
    );

    tl.to(
      "#bit7",
      1,
      {
        x: -870,
      },
      "-=0.86"
    );

    tl.to(
      "#bit1",
      1,
      {
        x: 870,
      },
      "-=0.86"
    );

    tl.to(
      "#bit5",
      1,
      {
        x: -870,
      },
      "-=0.86"
    );

    tl.to(
      "#bit4",
      1,
      {
        x: 870,
      },
      "-=0.86"
    );

    tl.to(
      "#bit8",
      1,
      {
        x: -870,
      },
      "-=0.86"
    );

    tl.to(
      "#bit6",
      1,
      {
        x: -870,
      },
      "-=0.86"
    );

    tl.to(
      "#bit3",
      1,
      {
        x: 870,
      },
      "-=0.86"
    );

    tl.to(
      "#bit10",
      1,
      {
        x: -870,
      },
      "-=0.86"
    );

    tl.to(
      "#bit11",
      1,
      {
        y: -400,
      },
      "-=1.46"
    );

    tl.to(
      "#bit9",
      1,
      {
        x: -870,
      },
      "-=0.86"
    );

    var scene = new ScrollMagic.Scene({
      triggerElement: "#bits-trigger1",
      duration: "100%",
    });

    scene.setTween(tl).addTo(controller);

    // Features Parralax

    var tl = new TimelineMax();
    var scene = new ScrollMagic.Scene({
      triggerElement: "#mahi-trigger",
      duration: "200%",
    });

    scene.setTween(tl).addTo(controller);

    tl.to("#mahi-img", 1, {
      y: 150,
    });

    var tl = new TimelineMax();
    var scene = new ScrollMagic.Scene({
      triggerElement: "#playtime-trigger",
      duration: "200%",
    });

    scene.setTween(tl).addTo(controller);

    tl.to("#playtime-img", 1, {
      y: 150,
    });

    this.ready = true;
  },
};
</script>
export const Features = [{
    id: "mahi",
    title: "Predictable delivery.",
    img: "predictable_delivery.jpg",
    content: `<p>Standardise your construction delivery systems and predict delivery in real-time. Mahi brings together planning, quality assurance, health and safety, analytics and data management all in the same platform.  </p><p>With configurable dashboards and reporting functionality that allows users to access and analyse data in real time, Mahi eliminates any communication lag between decision makers and workers on site, dramatically increasing productivity and reducing costs at all stages from planning through execution. </p>`,
    link: "http://mahi.software",
    screenshots: "mahi-screenshots.png",
    logo: "mahi-logo.svg",
  },
  {
    id: "playtime",
    title: "Creative Control.",
    img: "playtime.jpg",
    content: `<p>Playtime is an end-to-end solution that brings all the
functionality required by a 21st century live entertainment
business into a single cloud based platform.</p><p>Whether through ticketing, fundraising, project management, venue management, or one of its many other features, playtime delivers organization wide visibility and control for all aspects of your entertainment business.</p>`,
    link: "http://playtime.software",
    logo: "playtime-logo.png",
    screenshots: "playtime-screenshots.png",
  },
];
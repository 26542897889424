<template>
  <section :id="content.id" class="feature view-detect">
    <div :id="content.id + '-trigger'" class="feature-trigger"></div>
    <div class="img-wrapper">
      <img :id="content.id + '-img'" class="feature-image" :src="'images/' + content.img" :alt="content.title" />
      <h2>{{content.title}}</h2>
      <img class="screenshots" :src="'images/' + content.screenshots" :alt="content.title + ' screenshot'" />
    </div>
    <div class="container view-detect2">
      <div class="content">
        <div class="logo-wrapper">
          <a :href="content.link"><img :src="'images/' + content.logo" :alt="content.title + ' logo'" /></a>
        </div>
        <div>
          <div class="feature-copy" v-html="content.content"></div>
          <a class="btn" :href="content.link">View website</a>
        </div>
      </div>
    </div>
  </section>
</template>
 
<script>
export default {
  props: ["content"],
  data: function () {
    return {};
  },
  methods: {},
  mounted: function () {},
};
</script>
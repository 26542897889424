<template>
  <div class="qed-people view-detect" @click="activePerson = null">
    <div id="Team" name="Team"></div>
    <h2 id="team-title">{{ title }}</h2>

    <div class="team-bubbles" v-bind:class="{'has-active-person': activePerson}">
      <div
        @click="activatePerson(person, index, $event)"
        class="person"
        v-for="person, index in people"
        v-bind:key="person.name"
        v-bind:class="{'is-active-person': activePerson == person}"
      >
        <div class="person-img-wrapper">
          <img :src="'images/people/' + person.image" />
        </div>
        <div class="bio" ref="bios">
          <b>{{ person.name }}</b>
          <span class="bio-position">{{person.position}} 
            <a :href="person.linkedin" target="_blank" v-if="person.linkedin">
              <img src="../linked-in.png" />
            </a>
            </span>
          <p>{{person.bio}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PeopleHeader, People } from "../data/People";
export default {
  data: function () {
    return {
      title: PeopleHeader,
      people: People,
      activePerson: null,
    };
  },
  methods: {
    isInViewport: function (elem) {
      var bounding = elem.getBoundingClientRect();
      return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    activatePerson: function (person, index, event) {
      event.stopPropagation();
      let bioElement = this.$refs.bios[index];

      if (!this.isInViewport(bioElement)) {
        bioElement.classList.add("out-of-viewport");
      }

      if (this.activePerson == person) {
        this.activePerson = null;
      } else {
        this.activePerson = person;
      }
    },
  },
  mounted: function () {},
};
</script>
<template>
  <div>
    <Header></Header>
    <router-view></router-view>
    <SiteFooter></SiteFooter>
  </div>
</template>

<script>
import Header from "../components/Header";
import SiteFooter from "../components/Footer";

export default {
  components: { Header, SiteFooter },
  data: function () {
    return {
    };
  },
  mounted: function () {
  }
};
</script>
<template>
  <div class="container">
    <TeamBubbles></TeamBubbles>
  </div>
</template>

<script>
import TeamBubbles from "../components/TeamBubbles.vue";

export default {
  components: { TeamBubbles },
  data: function () {
    return {
     
    };
  },
  methods: {},
  mounted: function () {},
};
</script>
<template>
  <div class="main-header" v-bind:class="{'ready': ready}">
    <div class="header-content">
      <a href="#Home">
        <img id="main-logo" class="logo" src="/assets/logo.svg" alt="QED Software" />
      </a>

      <nav>
        <a class="contact-link" href="#Contact">Contact</a>
        <a
          class="bars"
          v-bind:class="{'menu-visible': menuVisible}"
          @click="menuVisible = !menuVisible"
        >
          <img class="lines" src="/assets/bars.svg" />
          <img class="times" src="/assets/times.svg" />
        </a>
      </nav>
    </div>

    <div
      class="menu"
      @click="menuVisible = !menuVisible"
      v-bind:class="{'menu-visible': menuVisible}"
    >
      <a href="#Products">Products</a>
      <a href="#Team">Team</a>
      <a href="#Contact">Contact</a>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      menuVisible: false,
      ready: false,
    };
  },
  methods: {},
  mounted: function () {
    setTimeout(() => {
      this.ready = true;
    });
  },
};
</script>
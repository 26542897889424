export const PeopleHeader = "A team of 20+ specialists driven towards innovation."

export const People = [
  {
    image: "Kevin.png",
    name: "Kevin McConnell",
    position: "Director",
    bio: "Kevin has over 25 years experience in the construction and infrastructure industries, serving at all levels, from frontline through to management roles across the world. During this time, Kevin has become a construction and infrastructure project delivery expert.",
    linkedin: "#",
  },
  {
    image: "Shuyu.png",
    name: "Shuyu",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "Mike.png",
    name: "Mike Maskill",
    position: "Head of Design",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "Charlie.png",
    name: "Charlie McDermott",
    position: "Founder/CEO of QED",
    bio: "The founder of Auckland’s Basement Theatre, Charlie went on to co-create and produce the immersive theatre experiences The Generation of Z which was a sell-out success in Edinburgh and London, and Pleasuredome The Musical - which sold 57,000 tickets in a three-month run in Auckland. He has worked with a variety of global VR & AR companies.",
    linkedin: "https://www.linkedin.com/in/charlie-mcdermott-4a25b578/"
  },
  {
    image: "Rob.png",
    name: "Rob Tapert",
    position: "Chairman",
    bio: "The longstanding producing partner of acclaimed director Sam Raimi, Rob has produced films which have grossed over a billion dollars worldwide including The Gift, The Grudge and the Evil Dead series. His television credits include Spartacus, Hercules: The Legendary Journeys and Xena: Warrior Princess, which played in more than 150 markets worldwide.",
    linkedin: "#",
  },
  {
    image: "Brad.png",
    name: "Brad Knewstubb",
    position: "Head of Design",
    bio: "Brad is an award winning designer with over 15 years experience. He has worked in product, graphic, immersive, interactive and spatial design. His interactive theatre project ‘Apollo 13: Mission Control’ was critically acclaimed and toured internationally. Brad has won numerous awards including an international Red Dot award and the Supreme Award for spatial design at the Best Design Awards.",
    linkedin: "#",
  },
  {
    image: "Craig.png",
    name: "Craig",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "G.png",
    name: "G",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "Graeme.png",
    name: "Graeme",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "Michael.png",
    name: "Michael",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "Ricardo.png",
    name: "Ricardo",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "Fiona.png",
    name: "Fiona",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "Kate.png",
    name: "Kate",
    position: "Position",
    bio: "This is the Bio",
  },
  {
    image: "Lucy.png",
    name: "Lucy",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "Marco.png",
    name: "Marco DeBortoli",
    position: "Head of Development",
    bio: "A recent graduate, at university Marco developed advanced machine vision and multi-sensor data fusion algorithms, creating autonomous wall climbing robots and designing complex engineering solutions. Professionally he has worked at six different internships where he designed and developed core backend software solutions for startups and global networking companies.",
    linkedin: "#",
  },
  {
    image: "Jerome.png",
    name: "Jerome Scott",
    position: "Founder/CTO",
    bio: "A specialist in IT architecture, Jerome has over 25 years experience in software development and project management. He has built and deployed large systems for British Telecom, Sky & Deutsche Telekom. As an entrepreneur, he built a technology solution from the ground-up which was acquired by a significant North American blue chip company.",
    linkedin: "#",
  },
  {
    image: "Reuben.png",
    name: "Reuben",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "JR.png",
    name: "JR",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "Sammie.png",
    name: "Sammie",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "Sandra.png",
    name: "Sandra Gupta",
    position: "Development Manager",
    bio: "Sandra comes to QED from a background of event software with experience in project management and end user testing. She thrives on getting the details just right and can be found in our Canadian office testing new features before they are released to customers. She keeps the crew organized by managing timelines and deliverables.",
    linkedin: "#",
  },
  {
    image: "Art.png",
    name: "Art",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "Saul.png",
    name: "Saul Nathan-Kazis",
    position: "Managing Director",
    bio: "Saul is a producer of live entertainment. He spent 7 years at Jean Doumanian productions where he worked on numerous award winning productions on Broadway, Off Broadway, and in London. Saul has developed and produced live entertainment in both the commercial and not for profit worlds, with work ranging from the traditional to the immersive.",
    linkedin: "#",
  },
  {
    image: "Casey.png",
    name: "Casey",
    position: "Position",
    bio: "This is the Bio",
    linkedin: "#",
  },
  {
    image: "Andy.png",
    name: "Andrew Lee",
    position: "Lead Front End Developer",
    bio: "Andrew is a digital developer and designer who has over 10 years of industry experience. He lives, breathes and dreams in code. He has worked on many projects of all sizes for a large number of industries. One notable project was leading the design and developing an online claims system for a NZ insurance company. The system was very well received by both their customers and staff and is still in use today.",
    linkedin: "#",
  }
]
import './styles.scss'
import Vue from 'vue/dist/vue.esm.js'
import VueRouter from 'vue-router'

import Main from './layouts/Main'

import Home from './pages/Home'
import Team from './pages/Team'


Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home },
  { path: '/Home', component: Home },
  { path: '/Contact', component: Home },
  { path: '/Team', component: Home },
  { path: '/Products', component: Home }
]

const router = new VueRouter({
  routes 
})

const app = new Vue({
  router,
  el: '#app',
  components: {
    Main
  },
  render (h) {
    return h(Main)
  },
})